<template>
  <div class="lg:border bg-transparent lg:border-gray-200 rounded-lg">
    <nav class="mx-auto max-w-screen-xl">
      <ul class="overflow-hidden lg:flex lg:border-r lg:border-gray-300 lg:rounded-none">
        <li
          v-for="(step, index) in steps"
          :key="index"
          class="relative overflow-hidden lg:flex-1 bg-white"
          :class="{ group: isEditingSync }"
          @click="tabSwitch(index)"
        >
          <div
            v-if="isEditingSync"
            class="absolute top-0 left-0 w-1 h-full bg-transparent group-hover:bg-gray-200 group-focus:bg-gray-200 lg:w-full lg:h-1 lg:bottom-0 lg:top-auto cursor-pointer"
          ></div>
          <active-step
            class="z-50"
            v-if="index >= activeStepSync"
            :label="step"
            :step="index"
            :active="index === activeStepSync"
          ></active-step>

          <completed-step v-else :label="step">
            <div
              v-if="index !== 0"
              class="hidden absolute top-0 left-0 w-3 inset-0 lg:block dark:bg-gray-700 dark:text-white"
              @click="tabSwitch(index)"
            >
              <svg class="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor" stroke-linejoin="round" />
              </svg>
            </div>
          </completed-step>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script lang="ts">
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import ActiveStep from './ActiveStep.vue';
import CompletedStep from './CompletedStep.vue';

@Component({
  components: {
    ActiveStep,
    CompletedStep,
  },
})
export default class Stepper extends Vue {
  @PropSync('activeStep') activeStepSync: number;
  @Prop() steps: string[];
  @Prop() click = true;
  @Prop() isEditingSync: boolean;

  stepperSubmit = false;

  tabSwitch(index: number) {
    if (this.click) {
      if (this.isEditingSync || this.activeStepSync >= index)
        if (this.activeStepSync === 0 || this.activeStepSync === 2 || this.activeStepSync === 4) {
          this.stepperSubmit = true;
          this.$emit('save', this.stepperSubmit, index);
        } else this.activeStepSync = index;
      this.stepperSubmit = false;
    }
  }
}
</script>

<style scoped>
.group {
  cursor: pointer;
}
</style>
